/* eslint-disable unicorn/no-array-for-each */
/* eslint-disable prettier/prettier */
import React from "react";
import clsx from "clsx";
import { useRouteMatch } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState, useEffect, useRef } from "react";

import {
  PageLayout,
  PageHeader,
} from "@transfr-inc/dashboard-components/layouts";

import {
  Column,
  ColumnType,
  SortDirection,
  ColumnOptions,
  DataTable,
  TableCellListContent,
} from "@transfr-inc/dashboard-components/data-table";

import {
  Button,
  useSearch,
  SearchBox,
} from "@transfr-inc/dashboard-components/forms";

import {
  Loader,
  Toolbar,
  Notification,
  SlashCircleIcon,
  BarOptionDefinition,
  CountText,
  PlaceholderProducts,
  Link,
  OverflowText,
} from "@transfr-inc/dashboard-components";

import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";
import { useLiveClassroomListCount } from "@transfr-inc/dashboard-sockets";

import { NewClassroomModal } from "../creation/new-classroom-modal";
import { BulkDeleteClassroomModal } from "../../../components/common/bulk-delete-classroom-modal";
import { OrganizationFilter } from "../../../components/custom/forms";
import { classroomUrlMap } from "../../../lib/classroom-url-map";
import { ClassroomRoute, RootPath } from "../../../lib/routes";
import { ShortcutAction } from "../../../components/shortcuts";
import { DemoBadge } from "../../../components/custom/content";

import { Role } from "../../../models/role";

import "./index.scss";

const populateSortKeys = (data) => {
  for (const classroom of data) {
    classroom.instructorSortKey =
      !classroom.instructors || classroom.instructors.length > 1
        ? classroom.totalInstructors.toString()
        : `${classroom.instructors[0].lastName.toLowerCase()}*${classroom.instructors[0].firstName.toLowerCase()} `;
  }
};

export default function ClassroomList({ responsiveStyles = {}, className }) {
  const { ceEnabled } = useStoreState((store) => store.app);

  const { currentUser, role, features } = useStoreState(
    (store) => store.account
  );
  const { classrooms, loadingClassrooms } = useStoreState(
    (state) => state.organization
  );
  const { missionControlEnabled } = useStoreState((state) => state.app);
  const { getClassrooms } = useStoreActions((state) => state.organization);
  const { organizationCode, userId, token } = currentUser;
  const [stateData, setStateData] = useState();
  const [cellRefs, setCellRefs] = useState({});
  const [stateNotification, setStateNotification] = useState();

  const { url } = useRouteMatch(`${RootPath}${ClassroomRoute.path}`);
  const { createClassroomEnabled, deleteClassroomEnabled } = features;

  const [showDelete, setShowDelete] = useState(false);
  const [deleteClassroomList, setDeleteClassroomList] = useState([]);

  const originalDataRef = useRef();
  const organizationRef = useRef();
  const searchTermRef = useRef();
  const recordCountExcludingSearch = useRef();

  const nameSearch = useSearch({
    data: [],
    searchKeys: ["name"],
  });

  const filterByOrganization = (option) => {
    const originalData = originalDataRef.current;
    const filteredData = option
      ? originalData.filter((value) => value.organizationCode == option.code)
      : originalData;

    setStateData(filteredData);
    nameSearch.updateData(filteredData);

    recordCountExcludingSearch.current = filteredData.length;
  };
  const onOrganizationChange = (option) => {
    filterByOrganization(option);
    organizationRef.current = option;
    if (searchTermRef.current) {
      onNameTermChange(searchTermRef.current);
    }
  };

  const [showCreateClassroomModal, setShowCreateClassroomModal] = useState("");

  const onResultSelected = (option) => {
    const filteredData = [option];
    setStateData(filteredData);
    nameSearch.updateData(filteredData);
    searchTermRef.current = option.name;
  };

  const onNameTermChange = (term) => {
    searchTermRef.current = term;
    // ensure that results that reflect the selected orgaization. If an org is not currently selected,
    // then the original data set should be utilized here.
    filterByOrganization(organizationRef.current);
    const results = nameSearch.queryData(term);
    setStateData(results);
  };

  const launchNotification = async (notification) => {
    setStateNotification(notification);
    setTimeout(() => {
      setStateNotification();
    }, 10000);
  };

  useEffect(() => {
    if (classrooms) {
      populateSortKeys(classrooms);
      createComponentRefs(classrooms);
      originalDataRef.current = classrooms;
      recordCountExcludingSearch.current = classrooms.length;

      // Update filter results
      organizationRef.current
        ? filterByOrganization(organizationRef.current)
        : nameSearch.updateData(classrooms);
      const results = nameSearch.queryData(searchTermRef.current);
      setStateData(results);
    }
  }, [classrooms]);

  const createComponentRefs = (classes) => {
    const newRefs = { ...cellRefs };
    classes.forEach((classroom) => {
      newRefs[classroom.classroomId] = React.createRef();
    });
    setCellRefs(newRefs);
  };

  const socketUrl = process.env.SOCKET_BRIDGE_URL;

  const missionControlClassList = missionControlEnabled
    ? useLiveClassroomListCount(socketUrl, token, organizationCode)
    : {};

  useEffect(() => {
    const handleUpdate = (classList) => {
      Object.keys(cellRefs).forEach((key) => {
        if (classList[key] && cellRefs[key]?.current?.updateData) {
          const roomCount = classList[key];
          cellRefs[key].current.updateData(roomCount);
        } else if (cellRefs[key] && cellRefs[key]?.current?.updateData) {
          cellRefs[key].current.updateData(0);
        }
      });
    };

    if (Object.keys(missionControlClassList)?.length) {
      handleUpdate(missionControlClassList);
    }
  }, [cellRefs, stateData, missionControlClassList]);

  const columns = [
    ...(deleteClassroomEnabled
      ? [new Column("select", /*title:*/ undefined, ColumnType.SELECTION)]
      : []),
    new Column(
      "name",
      "Name",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        sortKeyFields: ["name"],
        customFunction: (value, row) => {
          const path = {
            pathname: `${url}/${classroomUrlMap(row.products)}/${row.code}`,
            state: {
              classroomId: row.classroomId,
              breadcrumbLabel: "All Classrooms",
            },
          };

          return (
            <div className="classroom-name-cell">
              <OverflowText text={value}>
                <Link to={path}>{value}</Link>
              </OverflowText>
              <DemoBadge isDemo={row.isDemo}></DemoBadge>
            </div>
          );
        },
        isLiveDataColumn: true,
      })
    ),
    new Column(
      "organizationName",
      "organization",
      ColumnType.BASIC,
      new ColumnOptions({ sortingEnabled: true, overflowEnabled: true })
    ),
    new Column(
      "totalInstructors",
      "Instructors",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value, row) => {
          const renderedNames = [];

          if (row.instructors) {
            row.instructors.map((user) => {
              renderedNames.push(`${user.lastName}, ${user.firstName} `);
            });
          }
          return (
            <TableCellListContent
              collection={renderedNames}
              collectionCount={row.totalInstructors}
            />
          );
        },
        sortKeyFields: ["instructorSortKey"],
      })
    ),
    new Column(
      "totalTrainees",
      "Trainees",
      ColumnType.COUNT,
      new ColumnOptions({ sortingEnabled: true })
    ),
    new Column(
      "created",
      "Date Created",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) => formatDateDigits(value, true),
      })
    ),
    new Column(
      "code",
      "Code",
      ColumnType.BASIC,
      new ColumnOptions({ sortingEnabled: true, overflowEnabled: true })
    ),
    ...(ceEnabled
      ? [
          new Column(
            "products",
            "Products",
            ColumnType.CUSTOM,
            new ColumnOptions({
              sortingEnabled: true,
              customFunction: (value) => {
                return (
                  <PlaceholderProducts products={value}></PlaceholderProducts>
                );
              },
            })
          ),
        ]
      : []),
  ];

  const onDelete = (classroomsToDelete) => {
    setDeleteClassroomList(classroomsToDelete);
    setShowDelete(true);
  };

  const onRowRemoved = () => {
    getClassrooms({ userId, orgCode: organizationCode, role });
    setDeleteClassroomList([]);
  };

  const onClassroomAdded = (notification) => {
    launchNotification(notification);
    setShowCreateClassroomModal();
    notification && getClassrooms({ userId, orgCode: organizationCode, role });
  };

  const bottomBarOptions = [
    new BarOptionDefinition(onDelete, {
      text: "Delete",
      icon: ["fa-light", "fa-trash-can"],
      destructive: true,
    }),
  ];

  const orgFilter = (role === Role.MULTI_ORG_ADMIN.id ||
    role === Role.MULTI_ORG_VIEWER.id) && (
    <OrganizationFilter
      orgCode={organizationCode}
      onOrganizationSelected={onOrganizationChange}
    />
  );

  const toolbar = (
    <Toolbar>
      {createClassroomEnabled && (
        <Button
          icon={["fa-regular", "plus"]}
          primary
          title={"Create Classroom"}
          tooltip={"Create Classroom"}
          onClick={() => setShowCreateClassroomModal(true)}
          size="small"
        ></Button>
      )}
    </Toolbar>
  );

  const pageHeader = (
    <PageHeader
      title={"Classrooms"}
      badge={
        loadingClassrooms ? "..." : <CountText count={classrooms?.length} />
      }
      middlePane={orgFilter}
    >
      {toolbar}
    </PageHeader>
  );

  return (
    <PageLayout
      header={pageHeader}
      responsiveStyles={responsiveStyles}
      className={clsx("classroom-list-page", className, ceEnabled && "ce")}
    >
      {loadingClassrooms && <Loader fixed />}
      <SearchBox
        placeholder={"Search"}
        query={nameSearch.queryData}
        onSearchTermChange={onNameTermChange}
        onResultSelected={onResultSelected}
        customOptionLabel={(r) => `${r?.name}`}
      ></SearchBox>
      {recordCountExcludingSearch.current !== 0 && stateData?.length === 0 && (
        <ShortcutAction className="no-data-message" disabled>
          <SlashCircleIcon></SlashCircleIcon>
          No data to display. Please try adjusting your search or filters.
        </ShortcutAction>
      )}
      <DataTable
        columns={columns}
        data={stateData ?? []}
        className={clsx("classroom-list-data-table")}
        keyFields={["classroomId"]}
        defaultSortPropName="name"
        defaultSortDirection={SortDirection.ASCENDING}
        responsiveStyles={responsiveStyles}
        keyPrefix="classroom"
        bottomBarOptions={deleteClassroomEnabled && bottomBarOptions}
        tableContentName={{ singular: "classroom", plural: "classrooms" }}
        rowHeightConfig={{ large: 54, medium: 150, small: 200 }}
        virtualRowEnabled
        cellRefs={cellRefs}
      />
      {createClassroomEnabled && showCreateClassroomModal && (
        <NewClassroomModal
          show={showCreateClassroomModal}
          onClose={onClassroomAdded}
          orgCode={organizationCode}
          {...features}
        />
      )}
      <BulkDeleteClassroomModal
        onCancel={() => setShowDelete()}
        show={showDelete}
        deleteList={deleteClassroomList}
        onRowRemoved={onRowRemoved}
      />
      <div className="notification-container success-notification">
        {stateNotification && (
          <Notification
            type={stateNotification.type}
            icon={stateNotification.icon}
            onClose={() => {
              setStateNotification();
            }}
            closable
          >
            {stateNotification.message}
          </Notification>
        )}
      </div>
    </PageLayout>
  );
}
